<template>
  <div>
    <maca-titulo-pagina
      icono="el-icon-truck"
      nombrePagina="Transportistas"
    ></maca-titulo-pagina>

    <el-card :body-style="{ padding: '1.5%' }" style="margin: 2%">
      <div slot="header">
        <div style="margin-left: 0px">
          <el-input
            class="buscador tamañoInputBucar"
            prefix-icon="el-icon-search"
            placeholder="Presione ENTER para buscar"
            v-model="filtroNombre"
            @change="buscar"
          >
          </el-input>
          <!-- <el-button
            class="botonmasIcono"
            icon="el-icon-refresh"
            type="primary"
            circle
            style="margin-left: 0.2rem"
            @click="recargarTabla()"
          ></el-button> -->
        </div>
        <el-button
          class="botonmasIcono mostrarOcultarItem"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right: 0px"
          @click="$refs.modalNuevo.abrir()"
          >Nuevo Transportista</el-button
        >

        <el-button
          class="botonmasIcono ocultarMostrarItem"
          icon="el-icon-plus"
          type="primary"
          circle
          style="margin-left: 0.2rem"
          @click="$refs.modalNuevo.abrir()"
        ></el-button>
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column label="Centro" min-width="40">
            <template slot-scope="props">
              <div v-if="props.row.centroID != null">SI</div>
              <div v-else>NO</div>
            </template>
          </el-table-column>

          <el-table-column
            label="Razon Social/Apellido y Nombre del Transportista"
            min-width="60"
          >
            <template slot-scope="props">
              <span
                v-if="props.row.razonSocial != null"
                v-text="props.row.razonSocial"
              >
              </span>
              <span
                v-else
                v-text="props.row.apellido + ', ' + props.row.nombre"
              >
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="cuit" label="CUIT/CUIL"> </el-table-column>

          <el-table-column prop="telefono" label="Teléfono"> </el-table-column>
          <!--
          <el-table-column
            prop="detalleTransportistaTipoBeneficiario[0].detalleTipoBeneficiario.nombre"
            label="Tipo"
          >
          </el-table-column> -->
          <!--          <el-table-column width="60">
            <template slot-scope="props">
              <el-popover
                placement="top-start"
                title="Beneficiarios"
                width="200"
                trigger="hover"
                content="Lista de beneficiarios del transportista"
              >
                <el-button
                  slot="reference"
                  type="Info"  
                  circle
                  @click="$refs.modalListaBeneficiario.abrir(props.row.id)"
                  :disabled="!$store.getters.tienePermiso('M_USE')"
                >
                  <i class="el-icon-finished"></i>
                </el-button>
              </el-popover>
            </template>
          </el-table-column>-->
          <!--          <el-table-column width="60">
            <template slot-scope="props">
              <el-popover
                placement="top-start"
                title="Beneficiarios"
                width="200"
                trigger="hover"
                content="Asignar beneficiarios al transportista"
              >
                <el-button
                  slot="reference"
                  type="success"
                  circle
                  @click="$refs.modalNuevoBeneficiario.abrir(props.row.id)"
                  :disabled="!$store.getters.tienePermiso('M_USE')"
                >
                  <i class="el-icon-user"></i>
                </el-button>
              </el-popover>
            </template>
          </el-table-column>-->
          <el-table-column width="70">
            <template slot-scope="props">
              <el-button
                type="warning"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                :disabled="!$store.getters.tienePermiso('M_USE')"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>

    <modal-nuevo-beneficiario
      ref="modalNuevoBeneficiario"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo-beneficiario>

    <modal-listado-beneficiario
      ref="modalListaBeneficiario"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-listado-beneficiario>
  </div>
</template>
<style scoped>
.ocultarMostrarItem {
  display: none;
}
.mostrarOcultarItem {
  display: block;
}
@media screen and (max-device-width: 560px) {
  .ocultarMostrarItem {
    display: block;
  }
  .mostrarOcultarItem {
    display: none;
  }
}

.tamañoInputBucar {
  width: 15rem;
}
@media screen and (max-device-width: 385px) {
  .tamañoInputBucar {
    width: 12rem;
  }
}
</style>
<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";
import ModalNuevoBeneficiario from "./modales/nuevoBeneficiario";
import ModalListadoBeneficiario from "./modales/listaBeneficiarios";

export default {
  name: "usuario",
  components: {
    ModalNuevo,
    ModalModificar,
    ModalNuevoBeneficiario,
    ModalListadoBeneficiario,
  },
  data() {
    return {
      urlTabla: "/transportista/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,

      filtroNombre: null,
    };
  },
  methods: {
    buscar(value) {
      this.paramsTabla.nombre = this.filtroNombre;
      this.actualizarTabla = true;
    },
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el transportista."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/transportista/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Transportista borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleBloqueado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.bloqueado) {
        respuestaApi = await this.$maca.api.post("/user/bloquear", params);
      } else {
        respuestaApi = await this.$maca.api.post("/user/desbloquear", params);
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    filtroNombre: function (n, o) {
      // this.paramsTabla.nombre = this.filtroNombre;
      // this.actualizarTabla = true;
    },
  },
};
</script>
