<template>
  <div>
    <maca-modal
      titulo="Nuevo Transportista"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="130px"
        >
          <el-steps :active="active" finish-status="success">
            <el-step title="Datos personales"> </el-step>
            <el-step title="Ubicación"></el-step>
            <el-step title="Vehículo/s"></el-step>
          </el-steps>

          <!-- STEP 1 DATOS PERSONALES -->
          <div v-if="active == 0">
            <el-form-item label="Es Centro" prop="esCentro">
              <el-switch
                style="display: block; margin-top: 7px"
                v-model="valueCentro"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>

            <el-form-item
              v-if="valueCentro"
              label="Elegir Centro"
              prop="razonSocial"
            >
              <maca-select-box
                placeholder="Buscar centro por nombre"
                :item-label="['razonSocial']"
                v-model="form.centro"
                :url="urlCentro"
                :permitirInput="true"
                :clearable="true"
                :necesitaParams="true"
                :getParams="getParams"
              ></maca-select-box>
            </el-form-item>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Nombre" prop="nombre">
                  <el-input maxlength="20" v-model="form.nombre"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Apellido" prop="apellido">
                  <el-input v-model="form.apellido"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="CUIT" prop="cuitCuil">
              <input-mask
                class="el-input__inner"
                v-model="form.cuitCuil"
                mask="99-99.999.999-9"
                maskChar=" "
              ></input-mask>
            </el-form-item>
            <el-form-item label="Email" prop="email">
              <el-input class="inputEmail" v-model="form.email"></el-input>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Teléfono" prop="telefono">
                  <el-input maxlength="15" v-model="form.telefono"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- ----------------------- -->

          <!-- STEP 1 UBICACION -->
          <div v-if="active == 1">
            <el-form-item label="Domicilio" prop="domicilio">
              <el-input maxlength="30" v-model="form.domicilio"></el-input>
            </el-form-item>

            <!-- Selects para elegir ubicacionActual -->
            <div class="contenedor" style="margin-bottom: 22px">
              <el-form-item label="Departamento" prop="departamento">
                <el-select
                  v-model="form.departamentoID"
                  class="m-2"
                  placeholder="Seleccione un departamento de la lista"
                  @change="cambioDepartamento"
                >
                  <el-option
                    v-for="item in departamentosSelect"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                v-loading="cargandoLocalidades"
                label="Localidad"
                prop="localidad"
              >
                <el-select
                  :disabled="!form.departamentoID"
                  v-model="form.localidadID"
                  class="m-2"
                  placeholder="Seleccione una localidad de la lista"
                  @change="cambioLocalidad"
                >
                  <el-option
                    v-for="item in localidadesSelect"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Paraje" prop="paraje">
                <el-select
                  v-model="form.parajeID"
                  class="m-2"
                  placeholder="Seleccione un paraje de la lista"
                  clearable
                >
                  <el-option
                    v-for="item in parajesSelect"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- /////////////////////////////////// -->

            <div class="contenedor">
              <el-form-item label="Formato de coordenadas" prop="latLongFloat">
                <el-switch
                  style="display: block; margin-top: 7px"
                  v-model="valueFormatoDecimales"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Decimales"
                  inactive-text="Grados, minutos y segundos"
                >
                </el-switch>

                <label style="color: gray; font-style: italic"
                  >Se almacenará el formato seleccionado</label
                >
              </el-form-item>

              <div v-if="valueFormatoDecimales">
                <el-form-item label="Latitud" prop="latitud">
                  <el-input
                    v-model="form.latitud"
                    @input="onPasteLat"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Longitud" prop="longitud">
                  <el-input
                    v-model="form.longitud"
                    @input="onPasteLong"
                  ></el-input>
                </el-form-item>
              </div>

              <div v-else>
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-form-item label="." prop="grados">
                      <label>Latitud</label>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Grados" prop="gradosLat">
                      <el-input v-model.number="form.gradosLat">
                        <template slot="append">°</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Minutos" prop="minutosLat">
                      <el-input v-model.number="form.minutosLat">
                        <template slot="append">'</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Segundos" prop="segundosLat">
                      <el-input v-model.number="form.segundosLat">
                        <template slot="append">''</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="Direccion" prop="valueCardinalLat">
                      <el-select
                        v-model="form.valueCardinalLat"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in puntosCardinales"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-form-item label="." prop="grados">
                      <label>Longitud</label>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Grados" prop="gradosLon">
                      <el-input v-model.number="form.gradosLon">
                        <template slot="append">°</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Minutos" prop="minutosLon">
                      <el-input v-model.number="form.minutosLon">
                        <template slot="append">'</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Segundos" prop="segundosLon">
                      <el-input v-model.number="form.segundosLon">
                        <template slot="append">''</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="Direccion" prop="valueCardinalLon">
                      <el-select
                        v-model="form.valueCardinalLon"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in puntosCardinales"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- ----------------------- -->

          <!-- STEP 1 VEHICULOS -->
          <div v-if="active == 2">
            <div style="display: flex">
              <el-form-item
                label="Patente"
                prop="patente"
                style="margin: auto; margin-left: 0px; margin-right: 20px"
              >
                <el-input v-model="patente"></el-input>
              </el-form-item>

              <el-form-item
                label="Capacidad de carga de pasajeros"
                prop="capacidadMax"
                style="margin: auto; margin-left: 0px; margin-right: 0px"
              >
                <el-input-number
                  v-model="capacidadMax"
                  :min="1"
                ></el-input-number>
              </el-form-item>
              <el-button
                style="
                  height: 40px;
                  margin: auto;
                  margin-left: 0px;
                  margin-bottom: 0px;
                "
                plain
                v-if="active != 0"
                @click="agregarVehiculo"
                >Agregar</el-button
              >
            </div>

            <el-table
              :data="arrVehiculos"
              border
              stripe
              style="margin-top: 20px"
            >
              <el-table-column label="Patente" min-width="40">
                <template slot-scope="props">
                  {{ props.row.patente }}
                </template>
              </el-table-column>
              <el-table-column label="Capacidad" min-width="40">
                <template slot-scope="props">
                  {{ props.row.capacidadMax }}
                </template>
              </el-table-column>
              <el-table-column width="70">
                <template slot-scope="props">
                  <el-button
                    type="danger"
                    @click="eliminarVehiculo(props)"
                    circle
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- ----------------------- -->

          <div style="display: flex; margin-top: 15px">
            <div style="margin: auto; margin-right: 0px">
              <div style="display: flex">
                <el-button
                  style="margin: auto; margin-right: 10px"
                  v-if="active != 0"
                  @click="back"
                  >Atrás</el-button
                >
                <el-button
                  style="margin: auto; margin-left: 0px; margin-right: 0px"
                  v-if="active != 2"
                  @click="next"
                  >Siguiente</el-button
                >
                <maca-boton-guardar
                  style="margin: auto; margin-left: 0px; margin-right: 0px"
                  v-if="active == 2"
                  :onSubmit="onSubmit"
                ></maca-boton-guardar>
              </div>
            </div>
          </div>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
.contenedor {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}
</style>

<script>
export default {
  name: "nuevo-tipo-centro",

  data() {
    let _self = this;
    return {
      active: 0,

      puntosCardinales: [
        {
          value: "N",
          label: "N",
        },
        {
          value: "S",
          label: "S",
        },
        {
          value: "W",
          label: "W",
        },
        {
          value: "E",
          label: "E",
        },
      ],
      valueCardinal: "",
      patente: null,
      capacidadMax: null,
      arrVehiculos: [],
      form: {
        nombre: null,
        apellido: null,
        cuitCuil: null,
        domicilio: null,
        pais: null,
        centro: null,
        departamentoID: null,
        localidadID: null,
        provincia: null,
        departamento: null,
        localidad: null,
        latitud: null,
        longitud: null,
        telefono: null,
        email: null,
        traslado: null,
        gradosLat: null,
        minutosLat: null,
        segundosLat: null,
        valueCardinalLat: null,
        gradosLon: null,
        minutosLon: null,
        segundosLon: null,
        valueCardinalLon: null,
      },
      impedirClose: false,
      persona: null,
      valueCentro: false,
      urlCentro: "/centro/buscar",
      getParams: this.getParamssSelect,
      valueFormatoDecimales: true,

      formRules: {
        gradosLat: [
          {
            required: true,
            message: "Por favor introduzca grados.",
            trigger: "change",
          },
        ],
        latitud: [
          {
            required: true,
            message: "Por favor introduzca la latitud.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: true,
            message: "Por favor introduzca la longitud.",
            trigger: "change",
          },
        ],

        minutosLat: [
          {
            required: true,
            message: "Por favor introduzca minutos.",
            trigger: "change",
          },
        ],
        segundosLat: [
          {
            required: true,
            message: "Por favor introduzca segundos.",
            trigger: "change",
          },
        ],
        valueCardinalLat: [
          {
            required: true,
            message: "Por favor introduzca dirección.",
            trigger: "change",
          },
        ],
        gradosLon: [
          {
            required: true,
            message: "Por favor introduzca grados.",
            trigger: "change",
          },
        ],
        minutosLon: [
          {
            required: true,
            message: "Por favor introduzca minutos.",
            trigger: "change",
          },
        ],
        segundosLon: [
          {
            required: true,
            message: "Por favor introduzca segundos.",
            trigger: "change",
          },
        ],

        valueCardinalLon: [
          {
            required: true,
            message: "Por favor introduzca dirección.",
            trigger: "change",
          },
        ],
        domicilio: [
          {
            required: false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        apellido: [
          {
            required: true,
            message: "Por favor introduzca el apellido.",
            trigger: "change",
          },
        ],
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],

        cuitCuil: [
          {
            required: true,
            message: "Por favor introduzca el CUIT o CUIL",
            trigger: "change",
          },
        ],
        departamento: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        localidad: [
          {
            required: false,
            message: "Por favor introduzca la localidad.",
            trigger: "change",
          },
        ],

        telefono: [
          {
            required: false,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],
        centro: [
          {
            required: false,
            message: "Por favor introduzca el centro.",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Por favor introduzca el email.",
            trigger: "change",
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1: true,
      value2: true,
      valueTraslados: true,
      visible: false,
      edad: "",
      yaEligioFecha: false,
      eligioPais: false,

      mostrarMensaje: false,
      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",
      urlParaje: "/paraje/obtenerTodosSelect",

      getParamsProvincia: this.getParamsSelectProvincia,
      getParamsDepartamento: this.getParamsSelectDepartamento,
      getParamsLocalidad: this.getParamsSelectLocalidad,

      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,
      ubicacionActual: true,
      ubicacionIncompleta: false,

      cargandoLocalidades: false,
      departamentosSelect: [],
      localidadesSelect: [],
      parajesSelect: [],
    };
  },
  created() {},
  mounted() {
    this.getDepartamentos();
    this.getParajes();
    this.form.departamentoID = 1;
    this.getLocalidades();
  },
  methods: {
    //convertir grados min seg y cardinalidad a lat y lng
    ConvertDMSToDD(degrees, minutes, seconds, direction) {
      var dd = degrees + minutes / 60 + seconds / (60 * 60);

      if (direction == "S" || direction == "W") {
        dd = dd * -1;
      } // Don't do anything for N or E
      return dd;
    },
    ParseDMS(input) {
      var parts = input.split(/[^\d\w]+/);

      var lat = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
      var lng = ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7]);
    },
    next() {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          return false;
        } else {
          if (this.active++ > 2) this.active = 0;
        }
      });
    },
    back() {
      if (this.active-- < 0) this.active = 0;
    },
    agregarVehiculo() {
      if (this.patente != null && this.capacidadMax != null) {
        const patente = this.patente.replaceAll(/\s/g, "");
        this.arrVehiculos.push({
          patente: patente,
          capacidadMax: this.capacidadMax,
        });
        this.patente = null;
        this.capacidadMax = null;
      } else {
        this.$message({
          message: "Debe ingresar la patente y la capacidad del vehiculo.",
          type: "error",
        });
      }
    },
    eliminarVehiculo(props) {
      this.arrVehiculos.splice(props.$index, 1);
    },
    getPais(addrComponents) {
      for (var i = 0; i < addrComponents.length; i++) {
        if (addrComponents[i].types[0] == "country") {
          return addrComponents[i].short_name;
        }
        if (addrComponents[i].types.length == 2) {
          if (addrComponents[i].types[0] == "political") {
            return addrComponents[i].short_name;
          }
        }
      }
      return false;
    },

    async verificarCoordenadas() {
      let resultado;
      try {
        let geocoder = new google.maps.Geocoder(),
          latlng = new google.maps.LatLng(
            this.form.latitud,
            this.form.longitud
          );
        const result = await geocoder.geocode(
          { latLng: latlng },
          function (results, status) {
            console.log({ status });
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                resultado = { status: "OK", result: results[1] };
              } else {
                resultado = { status: "INVALID", result: results };
              }
            } else {
              //failed

              resultado = { status: "INVALID" };
            }
          }
        );
      } catch (error) {
        this.$message({
          message:
            "Error al utilizar los servicios de google. Detalle:" +
            error.message,
          type: "error",
        });
        return false;
      }

      console.log("resultado", resultado);
      if (resultado.status != null && resultado.status == "OK") {
        const pais = this.getPais(resultado.result.address_components);
        if (pais != null && pais == "AR") {
          return true;
        } else {
          this.$message({
            message:
              "Se encuentra fuera de argentina, seleccione otras coordenadas.",
            type: "error",
          });
        }
        return false;
      } else {
        this.$message({
          message: "Coordenadas invalidas.",
          type: "error",
        });
        return false;
      }
    },
    onPasteLat() {
      let latLong = this.form.latitud;

      console.log("incluye coma", latLong.includes(","));
      if (latLong.includes(",")) {
        latLong = latLong.split(",");

        this.form.latitud = latLong[0].trim();
        this.form.longitud = latLong[1].trim();
      } else {
        this.form.latitud = latLong;
      }
    },
    onPasteLong() {
      let latLong = this.form.longitud;
      if (latLong.includes(",")) {
        latLong = latLong.split(",");

        this.form.latitud = latLong[0].trim();
        this.form.longitud = latLong[1].trim();
      } else {
        this.form.longitud = latLong;
      }
    },

    //select departamento localidad y paraje
    cambioDepartamento() {
      console.log(this.form.departamentoID);
      this.getLocalidades();
    },
    cambioLocalidad() {
      console.log(this.form.localidadID);
      console.log(this.form);
    },
    async getLocalidades() {
      this.cargandoLocalidades = true;
      console.log(this.form.departamentoID);
      let params = {
        departamentoID: this.form.departamentoID,
        sinPaginar: 1,
      };
      await this.$maca.api
        .get("/localidad/obtenerTodos", params)
        .then((res) => {
          this.localidadesSelect = res.datos;
          console.log(this.localidadesSelect);
        });
      this.cargandoLocalidades = false;
    },
    async getDepartamentos() {
      let params = {
        provinciaID: 1,
        sinPaginar: 1,
      };
      await this.$maca.api.get(this.urlDepartamento, params).then((res) => {
        console.log(res);
        this.departamentosSelect = res.datos;
      });
    },
    async getParajes() {
      await this.$maca.api.get(this.urlParaje).then((res) => {
        this.parajesSelect = res.datos;
      });
    },
    ////////////////////////////////////////////////////////////////////
    getParamssSelect(query) {
      let params = "";
      params = { nombre: query };
      return params;
    },
    handleChange(node) {
      console.log("node", node);
      if (node.length < 4) {
        this.ubicacionIncompleta = true;
        this.ubicacionActual = true;
      } else {
        for (let i = 1; i <= node.length; i++) {
          let indice = i - 1;
          if (i == 1) {
            this.paisID = node[indice];
          } else if (i == 2) {
            this.provinciaID = node[indice];
          } else if (i == 3) {
            this.departamentoID = node[indice];
          } else if (i == 4) {
            this.localidadID = node[indice];
          } else if (i == 5) {
            this.parajeID = node[indice];
          }
        }

        this.ubicacionIncompleta = false;
        this.ubicacionActual = false;
      }
    },
    getPaises() {
      let respuestaApi = this.$maca.api.get("/pais/obtenerTodos", null);
      return respuestaApi;
    },
    getParamsSelectProvincia() {
      let params = "";
      if (this.form.pais != null) {
        params = { paisID: this.form.pais.id };
      }
      return params;
    },
    getParamsSelectDepartamento() {
      let params = "";
      if (this.form.provincia != null) {
        params = { provinciaID: this.form.provincia.id };
      }
      return params;
    },
    getParamsSelectLocalidad() {
      let params = "";
      if (this.form.departamento != null) {
        params = { departamentoID: this.form.departamento.id };
      }
      return params;
    },
    resetearValor(valor) {
      this.mostrarMensaje = true;
      if (valor == 1) {
        this.$refs.refProvincia.recargar();
        this.$refs.refProvinciaMobile.recargar();
        this.form.provincia = null;
        this.form.departamento = null;
        this.form.localidad = null;
        this.eligioPais = true;
      } else if (valor == 2) {
        this.$refs.refDepartamento.recargar();
        this.$refs.refDepartamentoMobile.recargar();
        this.form.departamento = null;
        this.form.localidad = null;
      } else if (valor == 3) {
        this.$refs.refLocalidad.recargar();
        this.$refs.refLocalidadMobile.recargar();
        this.form.localidad = null;
      }
    },
    calcularEdad() {
      let fecha1 = new Date(this.form.fechaN.toString());
      let fecha2 = new Date();
      var edad = fecha2.getFullYear() - fecha1.getFullYear();
      var m = fecha2.getMonth() - fecha1.getMonth();

      if (m < 0 || (m === 0 && fecha2.getDate() < fecha1.getDate())) {
        edad--;
        if (fecha2.getFullYear() == fecha1.getFullYear()) {
          edad++;
        }
      }
      this.edad = edad;
      this.yaEligioFecha = true;
    },
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.valueCentro = false;
      this.active = 0;
      this.capacidadMax = null;
      this.patente = null;
      this.arrVehiculos = [];
      this.form.nombre = null;
      this.form.apellido = null;
      this.form.cuitCuil = null;
      this.form.domicilio = null;
      this.form.pais = null;
      this.form.centro = null;
      this.form.provincia = null;
      this.form.departamento = null;
      this.form.localidad = null;
      this.form.latitud = null;
      this.form.longitud = null;
      this.form.telefono = null;
      this.form.email = null;
      this.form.traslado = null;
      this.form.gradosLat = null;
      this.form.minutosLat = null;
      this.form.segundosLat = null;
      this.form.valueCardinalLat = null;
      this.form.gradosLon = null;
      this.form.minutosLon = null;
      this.form.segundosLon = null;
      this.form.valueCardinalLon = null;
    },
    cerrar() {
      this.edad = "";
      this.yaEligioFecha = false;
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      if (!this.valueFormatoDecimales) {
        this.form.latitud = this.ConvertDMSToDD(
          this.form.gradosLat,
          this.form.minutosLat,
          this.form.segundosLat,
          this.form.valueCardinalLat
        );
        this.form.longitud = this.ConvertDMSToDD(
          this.form.gradosLon,
          this.form.minutosLon,
          this.form.segundosLon,
          this.form.valueCardinalLon
        );
      }
      const result = await this.verificarCoordenadas();
      if (result == false) {
        return false;
      }
      // let latitudDMS;
      // let longitudDMS;
      // if (!this.valueFormatoDecimales) {
      //   this.form.latitud = null;
      //   this.form.longitud = null;
      //   latitudDMS = {
      //     grados: this.form.gradosLat,
      //     minutos: this.form.minutosLat,
      //     segundos: this.form.segundosLat,
      //     direccion: this.form.valueCardinalLat,
      //   };

      //   longitudDMS = {
      //     grados: this.form.gradosLon,
      //     minutos: this.form.minutosLon,
      //     segundos: this.form.segundosLon,
      //     direccion: this.form.valueCardinalLon,
      //   };
      // }

      let razonSocialCentro;
      let cuitCentro;
      let domicilioCentro;
      let telefonoCentro;
      if (this.valueCentro) {
        let respuestaApi = await this.$maca.api.get("/centro/obtenerDatos", {
          id: this.form.centro.id,
        });
        if (respuestaApi != null) {
          razonSocialCentro = respuestaApi.datos.razonSocial;
          cuitCentro = respuestaApi.datos.cuit;
          domicilioCentro = respuestaApi.datos.domicilio;
          telefonoCentro = respuestaApi.datos.telefono;
        }
      }

      //quitar marcara al cuit
      let cuitcuilFormateado = this.form.cuitCuil
        .replaceAll("-", "")
        .replaceAll(".", "")
        .replaceAll(" ", "");

      // Hacer Post
      let params = {
        razonSocial: this.valueCentro ? razonSocialCentro : "",
        cuit: this.valueCentro ? cuitCentro : cuitcuilFormateado,
        domicilio: this.valueCentro
          ? domicilioCentro
          : this.form.domicilio != null
          ? this.form.domicilio
          : "",
        email: this.form.email,
        nombre: this.form.nombre != null ? this.form.nombre : "",
        apellido: this.form.apellido != null ? this.form.apellido : "",
        // latitud: this.form.latitud != null ? parseFloat(this.form.latitud) : "",
        // longitud:
        //   this.form.longitud != null ? parseFloat(this.form.longitud) : "",
        // latitudDMS: latitudDMS != null ? JSON.stringify(latitudDMS) : "",
        // longitudDMS: longitudDMS != null ? JSON.stringify(longitudDMS) : "",
        telefono: this.valueCentro
          ? telefonoCentro
          : this.form.telefono != null
          ? this.form.telefono
          : "",

        localidadID: this.localidadID != null ? this.localidadID : "",
      };

      let latitudDMS;
      let longitudDMS;
      if (!this.valueFormatoDecimales) {
        delete params.latitud;
        delete params.longitud;
        latitudDMS = {
          grados: this.form.gradosLat,
          minutos: this.form.minutosLat,
          segundos: this.form.segundosLat,
          direccion: this.form.valueCardinalLat,
        };
        longitudDMS = {
          grados: this.form.gradosLon,
          minutos: this.form.minutosLon,
          segundos: this.form.segundosLon,
          direccion: this.form.valueCardinalLon,
        };
        params.latitudDMS = JSON.stringify(latitudDMS);
        params.longitudDMS = JSON.stringify(longitudDMS);
      } else {
        params.latitud = this.form.latitud;
        params.longitud = this.form.longitud;
        params.latitudDMS = null;
        params.longitudDMS = null;
      }

      if (this.parajeID != null && this.parajeID != "") {
        params.parajeID = this.parajeID;
      }

      if (this.centro != null && this.centro != "") {
        params.centroID = this.form.centro.id;
      }

      if (this.arrVehiculos != null && this.arrVehiculos.length > 0) {
        params.detalleTransportistaVehiculo = JSON.stringify(this.arrVehiculos);
      } else {
        this.$message({
          message: "Por favor ingrese los vehículos del transportista",
          type: "error",
          showClose: true,
          duration: 0,
        });
        return false;
      }

      let respuestaApi = await this.$maca.api.post(
        "/transportista/crear",
        params
      );

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Transportista creado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}

.mostrarOcultarCard {
  display: block;
}

@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }

  .mostrarOcultarCard {
    display: none;
  }
}

.el-select .el-input {
  width: 110px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>