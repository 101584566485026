<template>
  <div>
    <maca-modal
      titulo="Beneficiarios"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card :body-style="{ padding: '1.5%' }" style="margin: 2%">
        <div slot="header">
          <div style="margin-left: 0px">
            <el-input
              class="buscador tamañoInputBucar"
              prefix-icon="el-icon-search"
              placeholder="Buscar"
              v-model="filtroNombre"
            >
            </el-input>
            <el-button
              class="botonmasIcono"
              icon="el-icon-refresh"
              type="primary"
              circle
              style="margin-left: 0.2rem"
              @click="recargarTabla()"
            ></el-button>
          </div>
        
          <el-button
            class="botonmasIcono ocultarMostrarItem"
            icon="el-icon-plus"
            type="primary"
            circle
            style="margin-left: 0.2rem"
            @click="$refs.modalNuevo.abrir()"
          ></el-button>
        </div>

        <div>
          <maca-datatable
            :url="urlTabla"
            :params="paramsTabla"
            :actualizar.sync="actualizarTabla"
            :bloquear.sync="bloquearTabla"
          >
            <el-table-column label="Centro">
              <template slot-scope="props">
                <div v-if="props.row.centroID != null">
                  <i class="el-icon-office-building"></i>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Apellido">
              <template slot-scope="props">
                <span v-text="props.row.apellido"> </span>
              </template>
            </el-table-column>

            <el-table-column label="Nombre">
              <template slot-scope="props">
                <span v-text="props.row.nombre"> </span>
              </template>
            </el-table-column>

            <el-table-column prop="razonSocial" label="Razon Social">
            </el-table-column>
            <el-table-column prop="cuitCuil" label="CUIT/CUIL">
            </el-table-column>

            <el-table-column prop="telefono" label="Teléfono">
            </el-table-column>
            <el-table-column label="Vive">
              <template slot-scope="props">
                <div v-if="props.row.fallecido == 0">
                  <span>SI </span>
                </div>
                <div v-else>
                  <span>NO </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="detalleTransportistaTipoBeneficiario[0].detalleTipoBeneficiario.nombre"
              label="Tipo"
            >
            </el-table-column>
        
            <el-table-column width="70">
              <template slot-scope="props">
                <el-button
                  type="danger"
                  @click="eliminar(props.row.id)"
                  circle
                  :disabled="!$store.getters.tienePermiso('B_USE')"
                >
                  <i class="el-icon-delete"></i>
                </el-button>
              </template>
            </el-table-column>
          </maca-datatable>
        </div>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    return {
      urlTabla: "/transportistaBeneficiario/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: false,
      bloquearTabla: true,

      filtroNombre: null,
      urlPais: "/pais/obtenerTodosSelect",

      form: {
        provincia: null,
        codigo: null,
      },
      impedirClose: false,
      persona: null,
      idPadre: 0,

      formRules: {
        provincia: [
          {
            required: true,
            message: "Por favor introduzca el nombre de la provincia.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
       async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el usuario."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/transportistaBeneficiario/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Beneficiario borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    abrir(idPadre) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.idPadre = idPadre;
      this.paramsTabla = { transportistaID: idPadre };

      this.mostrarTabla = true;
      this.actualizarTabla = true;  
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      console.log(this.form.pais);
      // Hacer Post
      let params = {
        nombre: this.form.provincia,
        paisID: this.idPadre,
      };

      let respuestaApi = await this.$maca.api.post("/provincia/crear", params);
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Provincia creada con éxito!",
          type: "success",
        });

        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
.ocultarMostrarItem {
  display: none;
}
.mostrarOcultarItem {
  display: block;
}
@media screen and (max-device-width: 560px) {
  .ocultarMostrarItem {
    display: block;
  }
  .mostrarOcultarItem {
    display: none;
  }
}

.tamañoInputBucar {
  width: 15rem;
}
@media screen and (max-device-width: 385px) {
  .tamañoInputBucar {
    width: 12rem;
  }
}
</style>