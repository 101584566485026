<template>
  <div>
    <maca-modal
      titulo="Asignación de beneficiario a transportista"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="130px"
        >
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item
                label="Buscar beneficiario por nombre"
                prop="nombre"
              >
                <maca-select-box
                  placeholder="Ingresar nombre de beneficiario"
                  :item-label="['apellido ', 'nombre']"
                  v-model="form.beneficiario"
                  :url="urlCentro"
                  :permitirInput="true"
                  :clearable="true"
                  @change="seleccionSelect"
                  :necesitaParams="true"
                  :getParams="getParams"
                ></maca-select-box>
              </el-form-item>
            </el-col>

            <el-col :span="7">
              <el-form-item label="O filtrar por localidad/paraje">
                <el-cascader
                  :props="props"
                  :show-all-levels="false"
                  ref="cascader"
                  placeholder="Seleccionar ubicación"
                  @change="handleChange"
                  :clearable="true"
                  @expand-change="clickHandleChange"
                ></el-cascader>
              </el-form-item>

              <span v-if="ubicacionIncompleta" style="color: red"
                >Debe seleccionar la localidad o paraje para poder realizar la
                búsqueda</span
              >
            </el-col>
            <el-col :span="2" style="margin-top: 48px">
              <el-button type="success" circle @click="filtrarBusqueda">
                <i class="el-icon-search"></i>
              </el-button>
            </el-col>
          </el-row>

          <maca-datatable
            :url="urlTabla"
            :params="paramsTabla"
            :actualizar.sync="actualizarTabla"
            :bloquear.sync="bloquearTabla"
            v-if="mostrarTabla"
          >
            <el-table-column
              prop="detallePersona.dni"
              label="DNI"
              :min-width="10"
            >
            </el-table-column>

            <el-table-column label="Apellido y Nombre" :min-width="17">
              <template slot-scope="props">
                <span
                  v-text="
                    props.row.detallePersona.apellido +
                    ', ' +
                    props.row.detallePersona.nombre
                  "
                >
                </span>
              </template>
            </el-table-column>
            <el-table-column label="Direccion" :min-width="20">
              <template slot-scope="props">
                <span
                  v-if="props.row.detallePersona.detalleParaje != null"
                  v-text="props.row.detallePersona.detalleParaje.nombre + ', '"
                >
                </span>
                <span
                  v-if="props.row.detallePersona.detalleLocalidad != null"
                  v-text="
                    props.row.detallePersona.detalleLocalidad.nombre + ', '
                  "
                >
                </span>
                <span
                  v-if="props.row.detallePersona.detalleDepartamento != null"
                  v-text="props.row.detallePersona.detalleDepartamento.nombre"
                >
                </span>
                <span
                  v-if="props.row.detallePersona.detalleProvincia != null"
                  v-text="
                    +' - ' + props.row.detallePersona.detalleProvincia.nombre
                  "
                >
                </span>
              </template>
            </el-table-column>

            <el-table-column
              prop="detallePersona.telefono"
              label="Telefono"
              :min-width="10"
            >
            </el-table-column>
            <el-table-column
              prop="detalleTipoBeneficiario[0].detalleTipoBeneficiario.nombre"
              label="Tipo Beneficio"
              :min-width="10"
            >
            </el-table-column>

            <el-table-column
              prop="detallePersona.fallecido"
              label="Fallecido"
              :min-width="10"
            >
              <template slot-scope="props">
                <div v-if="props.row.fallecido == 1">
                  <span style="color: red">SI </span>
                </div>
                <div v-else>
                  <span>NO </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="tieneAcompaniante"
              label="Acompañante"
              :min-width="10"
            >
              <template slot-scope="props">
                <div v-if="props.row.tieneAcompaniante == 1">
                  <span style="color: orange">SI </span>
                </div>
                <div v-else>
                  <span>NO </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="70">
              <template slot-scope="props">
                <el-button
                  type="success"
                  circle
                  @click="postApi(props.row)"
                >
                  <i class="el-icon-check"></i>
                </el-button>
              </template>
            </el-table-column>
          </maca-datatable>
        </el-form>
      </el-card>
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="ocultarMostrarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item
                label="Buscar beneficiario por nombre"
                prop="nombre"
              >
                <maca-select-box
                  placeholder="Ingresar nombre de beneficiario"
                  :item-label="['nombre']"
                  v-model="form.beneficiario"
                  :url="urlCentro"
                  :permitirInput="true"
                  :clearable="true"
                  ref="markupTree"
                  :necesitaParams="true"
                  :getParams="getParams"
                ></maca-select-box>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="O filtrar por localidad/paraje">
                <el-cascader
                  :props="props"
                  :show-all-levels="false"
                  placeholder="Seleccionar ubicación"
                  @change="handleChange"
                  @click="filtrarBusqueda"
                  @expand-change="clickHandleChange"
                ></el-cascader>
              </el-form-item>

              <span v-if="ubicacionIncompleta" style="color: red"
                >Debe seleccionar la localidad o paraje para poder realizar la
                búsqueda</span
              >
            </el-col>
            <el-col :span="12">
              <el-button type="success" circle>
                <i class="el-icon-search"></i>
              </el-button>
            </el-col>
          </el-row>

          <maca-datatable
            :url="urlTabla"
            :params="paramsTabla"
            :actualizar.sync="actualizarTabla"
            :bloquear.sync="bloquearTabla"
            v-if="mostrarTabla"
          >
            <el-table-column
              prop="detallePersona.dni"
              label="DNI"
              :min-width="10"
            >
            </el-table-column>
            <el-table-column label="Apellido y Nombre" :min-width="17">
              <template slot-scope="props">
                <span
                  v-text="
                    props.row.detallePersona.apellido +
                    ', ' +
                    props.row.detallePersona.nombre
                  "
                >
                </span>
              </template>
            </el-table-column>
            <el-table-column label="Direccion" :min-width="20">
              <template slot-scope="props">
                <span
                  v-if="props.row.detallePersona.detalleLocalidad != null"
                  v-text="
                    props.row.detallePersona.detalleLocalidad.nombre + ', '
                  "
                >
                </span>
                <span
                  v-if="props.row.detallePersona.detalleDepartamento != null"
                  v-text="props.row.detallePersona.detalleDepartamento.nombre"
                >
                </span>
                <span
                  v-if="props.row.detallePersona.detalleProvincia != null"
                  v-text="
                    +' - ' + props.row.detallePersona.detalleProvincia.nombre
                  "
                >
                </span>
              </template>
            </el-table-column>

            <el-table-column
              prop="detallePersona.telefono"
              label="Telefono"
              :min-width="10"
            >
            </el-table-column>
            <el-table-column
              prop="detalleTipoBeneficiario[0].detalleTipoBeneficiario.nombre"
              label="Tipo Beneficio"
              :min-width="10"
            >
            </el-table-column>

            <el-table-column
              prop="detallePersona.fallecido"
              label="Fallecido"
              :min-width="10"
            >
              <template slot-scope="props">
                <div v-if="props.row.fallecido == 1">
                  <span style="color: red">SI </span>
                </div>
                <div v-else>
                  <span>NO </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="tieneAcompaniante"
              label="Acompañante"
              :min-width="10"
            >
              <template slot-scope="props">
                <div v-if="props.row.tieneAcompaniante == 1">
                  <span style="color: orange">SI </span>
                </div>
                <div v-else>
                  <span>NO </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="70">
              <template slot-scope="props">
                <el-button
                  type="success"
                  circle
                  @click="postApi(props.row)"
                >
                  <i class="el-icon-check"></i>
                </el-button>
              </template>
            </el-table-column>
          </maca-datatable>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    let _self = this;
    return {
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;

          if (level === 0) {
            _self.$maca.api.get("/pais/obtenerTodos", null).then((res) => {
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else if (level === 1) {
            let params = { paisID: node.data.value, sinPaginar:1  };
            _self.$maca.api
              .get("/provincia/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 2) {
            // form.provincia = node.data.value;
            let params = { provinciaID: node.data.value, sinPaginar:1  };
            _self.$maca.api
              .get("/departamento/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));
                resolve(nodes);
              });
          } else if (level === 3) {
            let params = { departamentoID: node.data.value, sinPaginar:1  };
            _self.$maca.api
              .get("/localidad/obtenerTodos", params)
              .then((res) => {
                const nodes = res.datos.map((item) => ({
                  value: item.id,
                  label: item.nombre,
                }));

                resolve(nodes);
              });
          } else if (level === 4) {
            let params = { localidadID: node.data.value };
            _self.$maca.api.get("/paraje/obtenerTodos", params).then((res) => {
              const nodes = res.datos.map((item) => ({
                value: item.id,
                label: item.nombre,
              }));

              resolve(nodes);
            });
          } else {
            // form.paraje = node.data.value;
            resolve(null);
          }
        },
      },

      urlTabla: "/beneficiario/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      mostrarTabla: false,
      form: {
        pais: null,
        codigo: null,
        latitud: null,
        longitud: null,
        beneficiario:null,
      },
      impedirClose: false,
      persona: null,
      levelSeleccionado: 0,

      formRules: {
        beneficiario: [
          {
            required: true,
            message: "Por favor seleccione el beneficiario.",
            trigger: "change",
          },
        ],
      },

      value1: true,
      value2: true,

      getParams: this.getParamssSelect,
      urlCentro: "/beneficiario/buscar",
      esMail: false,
      esLocalidad: false,

      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,
      ubicacionActual: true,
      ubicacionIncompleta: false,
      longitudCentro: 0,
      latitudCentro: 0,
    };
  },
  
  mounted() {
    this.filtrarBusqueda();
  },
  methods: {
    seleccionSelect() {
      this.paramsTabla = { dni: this.form.beneficiario.dni };

      this.mostrarTabla = true;
      this.actualizarTabla = true;
    },
    filtrarBusqueda() {
      if (this.esLocalidad) {
        this.paramsTabla = { localidadID: this.localidadID };
      } else {
        this.paramsTabla = { parajeID: this.parajeID };
      }
      this.mostrarTabla = true;
      this.actualizarTabla = true;
    },
    // Get cascade selector data

    getProductType() {
      // This is to get data from the background

      this.$http
        .get("/product/productType/tree")

        .then((res) => {
          // Call the recursive method, remove the cascade data and assign the data to the cascade selector

          this.options = this.getTreeData(res.data);
        });
    }, // Recursive method

    getTreeData(data) {
      // Loop through the json data

      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // If children is an empty array, set children to undefined

          data[i].children = undefined;
        } else {
          // If children are not an empty array, continue to call this method recursively

          this.getTreeData(data[i].children);
        }
      }

      return data;
    },
    clickHandleChange(array) {
      /* let as = this.$refs.cascader.$refs.panel.getCheckedNodes();
      console.log(as);
      if (array.length != 1 && array.length != 2) {
        console.log("limpia");
        this.$refs.cascader.$refs.panel.clearCheckedNodes();
      }
      //se hace esto porque de cambiar a una localidad seleccionada, a otra y buscar sus parajes
      // se recargan el getPaises y se duplica todo*/
    },
    handleChange(node) {
      if (node.length < 4) {
        this.ubicacionIncompleta = true;
        this.ubicacionActual = true;
      } else {
        if (node.length == 4) {
          this.esLocalidad = true;
        } else this.esLocalidad = false;

        for (let i = 1; i <= node.length; i++) {
          let indice = i - 1;
          if (i == 1) {
            this.paisID = node[indice];
          } else if (i == 2) {
            this.provinciaID = node[indice];
          } else if (i == 3) {
            this.departamentoID = node[indice];
          } else if (i == 4) {
            this.localidadID = node[indice];
          } else if (i == 5) {
            this.parajeID = node[indice];
          }
        }

        this.ubicacionIncompleta = false;
        this.ubicacionActual = false;
      }
    },
    getParamssSelect(query) {
      let params = "";
      params = { nombre: query };
      return params;
    },
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit, datos) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi(datos) {

      console.log(datos); 
     
      let detalleBeneficiario = [
        {
          id: 0,
          beneficiarioID: datos.id,
          accion: "A",
        },
      ];
      // Hacer Post
      let params = {
        transportistaID: this.id,
        detalleBeneficiario: JSON.stringify(detalleBeneficiario),
      };

      let respuestaApi = await this.$maca.api.post(
        "/transportistaBeneficiario/crear",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Beneficiario agregado con éxito!",
          type: "success",
        });
        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
</style>