<template>
  <div>
    <maca-modal
      titulo="Modificar Transportista"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          v-loading="cargando"
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="130px"
        >
          <el-steps :active="active" finish-status="success">
            <el-step title="Datos personales"> </el-step>
            <el-step title="Ubicación"></el-step>
            <el-step title="Vehículo/s"></el-step>
          </el-steps>
          <!-- STEP 1 DATOS PERSONALES -->
          <div v-if="active == 0">
            <el-form-item label="Es Centro" prop="valueCentro">
              <el-switch
                style="display: block; margin-top: 7px"
                v-model="valueCentro"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="SI"
                inactive-text="NO"
                :active-value="true"
                :inactive-value="false"
              >
              </el-switch>
            </el-form-item>

            <el-form-item
              v-show="valueCentro"
              label="Elegir Centro"
              prop="razonSocial"
            >
              <maca-select-box
                ref="centroSelect"
                placeholder="Buscar centro por nombre"
                itemLabel="razonSocial"
                v-model="form.centro"
                :url="urlCentro"
                :permitirInput="true"
                :clearable="true"
                :necesitaParams="true"
                :getParams="getParamssSelect"
                @change="changeCentro"
              >
              </maca-select-box>
            </el-form-item>

            <el-row v-if="!valueCentro" :gutter="20">
              <el-col :span="12">
                <el-form-item label="Nombre" prop="nombre">
                  <el-input maxlength="20" v-model="form.nombre"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Apellido" prop="apellido">
                  <el-input v-model="form.apellido"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item v-if="!valueCentro" label="CUIL" prop="cuitCuil">
                  <input-mask
                    class="el-input__inner"
                    v-model="form.cuitCuil"
                    mask="99-99.999.999-9"
                    maskChar=" "
                  ></input-mask>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Email" prop="email">
              <el-input
                class="inputEmail"
                maxlength="300"
                v-model="form.email"
              ></el-input>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Teléfono" prop="telefono">
                  <el-input maxlength="15" v-model="form.telefono"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <!-- STEP 1 UBICACION -->
          <div v-if="active == 1">
            <el-form-item label="Domicilio" prop="domicilio">
              <el-input maxlength="30" v-model="form.domicilio"></el-input>
            </el-form-item>

            <!-- Selects para elegir ubicacionActual -->
            <div class="contenedor" style="margin-bottom: 22px">
              <el-form-item label="Departamento" prop="departamento">
                <el-select
                  v-model="form.departamentoID"
                  class="m-2"
                  placeholder="Seleccione un departamento de la lista"
                  @change="cambioDepartamento"
                >
                  <el-option
                    v-for="item in departamentosSelect"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                v-loading="cargandoLocalidades"
                label="Localidad"
                prop="localidad"
              >
                <el-select
                  :disabled="!form.departamentoID"
                  v-model="form.localidadID"
                  class="m-2"
                  placeholder="Seleccione una localidad de la lista"
                  @change="cambioLocalidad"
                >
                  <el-option
                    v-for="item in localidadesSelect"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Paraje" prop="paraje">
                <el-select
                  v-model="form.parajeID"
                  class="m-2"
                  placeholder="Seleccione un paraje de la lista"
                  clearable
                >
                  <el-option
                    v-for="item in parajesSelect"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- /////////////////////////////////// -->
            <div class="contenedor">
              <el-form-item label="Formato de coordenadas" prop="latLongFloat">
                <el-switch
                  style="display: block; margin-top: 7px"
                  v-model="valueFormatoDecimales"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Decimales"
                  inactive-text="Grados, minutos y segundos"
                >
                </el-switch>

                <label style="color: gray; font-style: italic"
                  >Se almacenará el formato seleccionado</label
                >
              </el-form-item>

              <div v-if="valueFormatoDecimales">
                <el-form-item label="Latitud" prop="latitud">
                  <el-input
                    v-model="form.latitud"
                    @input="onPasteLat"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Longitud" prop="longitud">
                  <el-input
                    v-model="form.longitud"
                    @input="onPasteLong"
                  ></el-input>
                </el-form-item>
              </div>

              <div v-else>
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-form-item label="." prop="grados">
                      <label>Latitud</label>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Grados" prop="gradosLat">
                      <el-input v-model.number="form.gradosLat">
                        <template slot="append">°</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Minutos" prop="minutosLat">
                      <el-input v-model.number="form.minutosLat">
                        <template slot="append">'</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Segundos" prop="segundosLat">
                      <el-input v-model.number="form.segundosLat">
                        <template slot="append">''</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="Direccion" prop="valueCardinalLat">
                      <el-select
                        v-model="form.valueCardinalLat"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in puntosCardinales"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-form-item label="." prop="grados">
                      <label>Longitud</label>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Grados" prop="gradosLon">
                      <el-input v-model.number="form.gradosLon">
                        <template slot="append">°</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Minutos" prop="minutosLon">
                      <el-input v-model.number="form.minutosLon">
                        <template slot="append">'</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="Segundos" prop="segundosLon">
                      <el-input v-model.number="form.segundosLon">
                        <template slot="append">''</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item label="Direccion" prop="valueCardinalLon">
                      <el-select
                        v-model="form.valueCardinalLon"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in puntosCardinales"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>

          <!-- STEP 1 VEHICULOS -->
          <div v-if="active == 2">
            <div style="display: flex">
              <el-form-item
                label="Patente"
                prop="patente"
                style="margin: auto; margin-left: 0px; margin-right: 20px"
              >
                <el-input v-model="patente"></el-input>
              </el-form-item>

              <el-form-item
                label="Capacidad de carga de pasajeros"
                prop="capacidadMax"
                style="margin: auto; margin-left: 0px; margin-right: 0px"
              >
                <el-input-number
                  v-model="capacidadMax"
                  :min="1"
                ></el-input-number>
              </el-form-item>
              <el-button
                style="
                  height: 40px;
                  margin: auto;
                  margin-left: 0px;
                  margin-bottom: 0px;
                "
                plain
                v-if="active != 0"
                @click="agregarVehiculo"
                >Agregar</el-button
              >
            </div>

            <el-table
              :data="arrVehiculos"
              border
              stripe
              style="margin-top: 20px"
            >
              <el-table-column label="Patente" min-width="40">
                <template slot-scope="props">
                  {{ props.row.patente }}
                </template>
              </el-table-column>
              <el-table-column label="Capacidad" min-width="40">
                <template slot-scope="props">
                  {{ props.row.capacidadMax }}
                </template>
              </el-table-column>
              <el-table-column width="70">
                <template slot-scope="props">
                  <el-button
                    type="danger"
                    @click="eliminarVehiculo(props)"
                    circle
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- ----------------------- -->

          <div style="display: flex; margin-top: 15px">
            <div style="margin: auto; margin-right: 0px">
              <div style="display: flex">
                <el-button
                  style="margin: auto; margin-right: 10px"
                  v-if="active != 0"
                  @click="back"
                  >Atrás</el-button
                >
                <el-button
                  style="margin: auto; margin-left: 0px; margin-right: 0px"
                  v-if="active != 2"
                  @click="next"
                  >Siguiente</el-button
                >
                <maca-boton-guardar
                  style="margin: auto; margin-left: 0px; margin-right: 0px"
                  v-if="active == 2"
                  :onSubmit="onSubmit"
                ></maca-boton-guardar>
              </div>
            </div>
          </div>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>
<style scoped>
.contenedor {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}
</style>
<script>
export default {
  name: "modificar-usuario",
  data() {
    return {
      active: 0,
      cargando: false,
      cargandoLocalidades: false,
      patente: null,
      capacidadMax: null,
      arrVehiculos: [],
      arrVehiculosBaja: [],
      form: {
        nombre: null,
        capacidadMax: null,
        apellido: null,
        razonSocial: null,
        cuitCuil: null,
        domicilio: null,
        departamentoID: null,
        localidadID: null,
        parajeID: null,
        latitud: null,
        longitud: null,
        telefono: null,
        email: null,
        traslado: null,
        gradosLat: null,
        minutosLat: null,
        segundosLat: null,
        valueCardinalLat: null,
        gradosLon: null,
        minutosLon: null,
        segundosLon: null,
        valueCardinalLon: null,
        centro: null,
      },
      impedirClose: false,
      persona: null,
      valueCentro: false,
      urlCentro: "/centro/buscar",
      valueFormatoDecimales: true,
      puntosCardinales: [
        {
          value: "N",
          label: "N",
        },
        {
          value: "S",
          label: "S",
        },
        {
          value: "W",
          label: "W",
        },
        {
          value: "E",
          label: "E",
        },
      ],
      valueCardinal: "",
      formRules: {
        gradosLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        latitud: [
          {
            required: !this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: !this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],

        minutosLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        segundosLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        valueCardinalLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        gradosLon: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        minutosLon: [
          {
            required: !this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        segundosLon: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],

        valueCardinalLon: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        domicilio: [
          {
            required: false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        email: [
          {
            required: false,
            message: "Por favor introduzca el email.",
            trigger: "change",
          },
        ],

        telefono: [
          {
            required: false,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1: true,
      value2: true,
      valueTraslados: true,
      visible: false,
      edad: "",
      yaEligioFecha: false,
      eligioPais: false,

      mostrarMensaje: false,
      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",

      getParamsProvincia: this.getParamsSelectProvincia,
      getParamsDepartamento: this.getParamsSelectDepartamento,
      getParamsLocalidad: this.getParamsSelectLocalidad,

      departamentosSelect: [],
      localidadesSelect: [],
      parajesSelect: [],
    };
  },
  created() {},
  mounted() {
    this.getDepartamentos();
    this.getParajes();
    this.form.departamentoID = 1;
    // this.getParamssSelect("centro");
  },
  methods: {
    //convertir grados min seg y cardinalidad a lat y lng
    ConvertDMSToDD(degrees, minutes, seconds, direction) {
      var dd = degrees + minutes / 60 + seconds / (60 * 60);

      if (direction == "S" || direction == "W") {
        dd = dd * -1;
      } // Don't do anything for N or E
      return dd;
    },
    ParseDMS(input) {
      var parts = input.split(/[^\d\w]+/);

      var lat = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
      var lng = ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7]);
    },
    next() {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          return false;
        } else {
          if (this.active++ > 2) this.active = 0;
        }
      });
    },
    back() {
      if (this.active-- < 0) this.active = 0;
    },
    agregarVehiculo() {
      if (this.patente != null && this.capacidadMax != null) {
        const patente = this.patente.replaceAll(/\s/g, "");
        this.arrVehiculos.push({
          patente: patente,
          capacidadMax: this.capacidadMax,
        });
        this.patente = null;
        this.capacidadMax = null;
      } else {
        this.$message({
          message: "Debe ingresar la patente y la capacidad del vehiculo.",
          type: "error",
        });
      }
    },
    eliminarVehiculo(props) {
      this.arrVehiculos.splice(props.$index, 1);
    },
    changeCentro() {
      console.log("centro cambiado", this.form.centro);
    },
    getPais(addrComponents) {
      for (var i = 0; i < addrComponents.length; i++) {
        if (addrComponents[i].types[0] == "country") {
          return addrComponents[i].short_name;
        }
        if (addrComponents[i].types.length == 2) {
          if (addrComponents[i].types[0] == "political") {
            return addrComponents[i].short_name;
          }
        }
      }
      return false;
    },

    async verificarCoordenadas() {
      let resultado;
      try {
        let geocoder = new google.maps.Geocoder(),
          latlng = new google.maps.LatLng(
            this.form.latitud,
            this.form.longitud
          );
        const result = await geocoder.geocode(
          { latLng: latlng },
          function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                resultado = { status: "OK", result: results[1] };
              } else {
                resultado = { status: "INVALID", result: results };
              }
            } else {
              //failed

              resultado = { status: "INVALID" };
            }
          }
        );
      } catch (error) {
        this.$message({
          message:
            "Error al utilizar los servicios de google. Detalle:" +
            error.message,
          type: "error",
        });
        return false;
      }

      console.log("resultado", resultado);
      if (resultado.status != null && resultado.status == "OK") {
        const pais = this.getPais(resultado.result.address_components);
        if (pais != null && pais == "AR") {
          return true;
        } else {
          this.$message({
            message:
              "Se encuentra fuera de argentina, seleccione otras coordenadas.",
            type: "error",
          });
        }
        return false;
      } else {
        this.$message({
          message: "Coordenadas invalidas.",
          type: "error",
        });
        return false;
      }
    },
    onPasteLat() {
      let latLong = this.form.latitud;

      if (latLong.includes(",")) {
        latLong = latLong.split(",");

        this.form.latitud = latLong[0].trim();
        this.form.longitud = latLong[1].trim();
      } else {
        this.form.latitud = latLong;
      }
    },
    onPasteLong() {
      let latLong = this.form.longitud;
      if (latLong.includes(",")) {
        latLong = latLong.split(",");

        this.form.latitud = latLong[0].trim();
        this.form.longitud = latLong[1].trim();
      } else {
        this.form.longitud = latLong;
      }
    },
    getParamssSelect(query) {
      let params = "";
      params = { nombre: query };
      return params;
    },
    async getLocalidades() {
      this.cargandoLocalidades = true;
      console.log(this.form.departamentoID);
      let params = {
        departamentoID: this.form.departamentoID,
        sinPaginar: 1,
      };
      await this.$maca.api
        .get("/localidad/obtenerTodos", params)
        .then((res) => {
          this.localidadesSelect = res.datos;
          console.log("select localidad", this.localidadesSelect);
        });
      this.cargandoLocalidades = false;
    },
    async getDepartamentos() {
      let params = {
        provinciaID: 1,
        sinPaginar: 1,
      };
      await this.$maca.api
        .get("/departamento/obtenerTodosSelect", params)
        .then((res) => {
          console.log(res);
          this.departamentosSelect = res.datos;
        });
    },
    async getParajes() {
      await this.$maca.api.get("/paraje/obtenerTodosSelect").then((res) => {
        this.parajesSelect = res.datos;
      });
    },
    cambioDepartamento() {
      console.log("departamentoID", this.form.departamentoID);
      this.getLocalidades();
    },
    cambioLocalidad() {
      console.log("localidadID", this.form.localidadID);
    },
    abrir(id) {
      this.$refs.modal.abrir();
      console.log("getparams", this.getParams);
      if (this.$refs.form) {
        this.$refs.form.resetFields();
        this.form.gradosLat = null;
        this.form.minutosLat = null;
        this.form.segundosLat = null;
        this.form.valueCardinalLat = null;
        this.form.gradosLon = null;
        this.form.minutosLon = null;
        this.form.segundosLon = null;
        this.form.valueCardinalLon = null;
        this.valueFormatoDecimales = true;

        this.active = 0;
        this.capacidadMax = null;
        this.patente = null;
        this.arrVehiculos = [];
      }

      this.id = id;
      this.getDatos();

      //console.log("refs centroselect", this.$refs.centroSelect.recargar());
      //this.$refs.centroSelect.recargar();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get(
        "/transportista/obtenerDatos",
        {
          id: this.id,
        }
      );

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.datos.nombre;
        this.form.apellido = respuestaApi.datos.apellido;

        this.form.cuitCuil = respuestaApi.datos.cuit;
        this.form.domicilio = respuestaApi.datos.domicilio;
        this.form.telefono = respuestaApi.datos.telefono;
        this.form.email = respuestaApi.datos.email;
        this.capacidadMax = respuestaApi.datos.capacidadMax;
        this.form.latitud = respuestaApi.datos.latitud;
        this.form.longitud = respuestaApi.datos.longitud;

        if (respuestaApi.datos.latitudDMS != null) {
          this.form.gradosLat = respuestaApi.datos.latitudDMS.grados;
          this.form.minutosLat = respuestaApi.datos.latitudDMS.minutos;
          this.form.segundosLat = respuestaApi.datos.latitudDMS.segundos;
          this.form.valueCardinalLat = respuestaApi.datos.latitudDMS.direccion;
          this.form.gradosLon = respuestaApi.datos.longitudDMS.grados;
          this.form.minutosLon = respuestaApi.datos.longitudDMS.minutos;
          this.form.segundosLon = respuestaApi.datos.longitudDMS.segundos;
          this.form.valueCardinalLon = respuestaApi.datos.longitudDMS.direccion;
        }

        if (respuestaApi.datos.centroID != null) {
          this.valueCentro = true;
          this.form.razonSocial = respuestaApi.datos.razonSocial;
          this.getParamssSelect(respuestaApi.datos.detalleCentro.razonSocial);
          this.form.centro = {
            id: respuestaApi.datos.detalleCentro.id,
            razonSocial: respuestaApi.datos.detalleCentro.razonSocial,
            nombre: respuestaApi.datos.detalleCentro.razonSocial,
          };
        } else {
          this.valueCentro = false;
        }

        if (this.form.latitud != null && this.form.longitud != null)
          this.valueFormatoDecimales = true;
        else this.valueFormatoDecimales = false;

        if (
          respuestaApi.datos.detalleParaje != null &&
          respuestaApi.datos.detalleParaje.id != null
        ) {
          this.form.parajeID = respuestaApi.datos.detalleParaje.id;
        }
        if (
          respuestaApi.datos.detalleDepartamento != null &&
          respuestaApi.datos.detalleDepartamento.id != null
        ) {
          this.form.departamentoID = respuestaApi.datos.detalleDepartamento.id;
        }
        await this.getLocalidades();
        if (
          respuestaApi.datos.detalleLocalidad != null &&
          respuestaApi.datos.detalleLocalidad.id != null
        ) {
          this.form.localidadID = respuestaApi.datos.detalleLocalidad.id;
        }

        this.arrVehiculos =
          respuestaApi.datos.detalleTransportistaVehiculo != null &&
          respuestaApi.datos.detalleTransportistaVehiculo.length > 0
            ? respuestaApi.datos.detalleTransportistaVehiculo.slice()
            : [];
        this.arrVehiculosBaja =
          respuestaApi.datos.detalleTransportistaVehiculo != null &&
          respuestaApi.datos.detalleTransportistaVehiculo.length > 0
            ? respuestaApi.datos.detalleTransportistaVehiculo.slice()
            : [];
      } else {
        this.cerrar();
      }

      this.cargando = false;
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      if (!this.valueFormatoDecimales) {
        this.form.latitud = this.ConvertDMSToDD(
          this.form.gradosLat,
          this.form.minutosLat,
          this.form.segundosLat,
          this.form.valueCardinalLat
        );
        this.form.longitud = this.ConvertDMSToDD(
          this.form.gradosLon,
          this.form.minutosLon,
          this.form.segundosLon,
          this.form.valueCardinalLon
        );
      }
      const result = await this.verificarCoordenadas();
      if (result == false) {
        return false;
      }
      console.log("this.form", this.form);
      // let latitudDMS;
      // let longitudDMS;
      // if (!this.valueFormatoDecimales) {
      //   this.form.latitud = null;
      //   this.form.longitud = null;
      //   latitudDMS = {
      //     grados: this.form.gradosLat,
      //     minutos: this.form.minutosLat,
      //     segundos: this.form.segundosLat,
      //     direccion: this.form.valueCardinalLat,
      //   };

      //   longitudDMS = {
      //     grados: this.form.gradosLon,
      //     minutos: this.form.minutosLon,
      //     segundos: this.form.segundosLon,
      //     direccion: this.form.valueCardinalLon,
      //   };
      // }

      //quitar marcara al cuit
      let cuitcuilFormateado = this.form.cuitCuil
        .replaceAll("-", "")
        .replaceAll(".", "")
        .replaceAll(" ", "");

      // Hacer Post
      let params = {
        id: this.id,

        cuit: cuitcuilFormateado,

        latitud: this.form.latitud,
        longitud: this.form.longitud,
        // latitudDMS: latitudDMS != null ? JSON.stringify(latitudDMS) : "",
        // longitudDMS: longitudDMS != null ? JSON.stringify(longitudDMS) : "",
      };

      let latitudDMS;
      let longitudDMS;
      if (!this.valueFormatoDecimales) {
        params.latitud = null;
        params.longitud = null;
        latitudDMS = {
          grados: this.form.gradosLat,
          minutos: this.form.minutosLat,
          segundos: this.form.segundosLat,
          direccion: this.form.valueCardinalLat,
        };

        longitudDMS = {
          grados: this.form.gradosLon,
          minutos: this.form.minutosLon,
          segundos: this.form.segundosLon,
          direccion: this.form.valueCardinalLon,
        };
        params.latitudDMS = JSON.stringify(latitudDMS);
        params.longitudDMS = JSON.stringify(longitudDMS);
      }

      if (this.form.razonSocial != null) {
        params.razonSocial = this.form.razonSocial;
      }
      if (this.form.domicilio != null) {
        params.domicilio = this.form.domicilio;
      }

      if (this.form.departamentoID != null && this.form.departamentoID != "") {
        params.departamentoID = this.form.departamentoID;
      }

      if (this.form.localidadID != null && this.form.localidadID != "") {
        params.localidadID = this.form.localidadID;
      }

      if (this.form.parajeID != null && this.form.parajeID != "") {
        params.parajeID = this.form.parajeID;
      }

      if (this.form.telefono != null) {
        params.telefono = this.form.telefono;
      }

      if (this.form.email != null && this.form.email != "") {
        params.email = this.form.email;
      }

      if (this.valueCentro == true) {
        if (this.form.centro != null) {
          params.centroID = this.form.centro.id;
        } else {
          this.$message({
            message: "Seleccione un centro",
            type: "error",
            showClose: true,
            duration: 0,
          });
          return false;
        }
      } else {
        params.nombre = this.form.nombre;
        params.apellido = this.form.apellido;
      }

      if (this.arrVehiculos != null && this.arrVehiculos.length > 0) {
        const arrBaja = this.arrVehiculosBaja.map((item) => {
          return {
            id: item.id,
            patente: item.patente,
            capacidadMax: item.capacidadMax,
            accion: "B",
          };
        });
        const arrAlta = this.arrVehiculos.map((item) => {
          return {
            id: 0,
            patente: item.patente,
            capacidadMax: item.capacidadMax,
            accion: "A",
          };
        });
        const arrParams = arrBaja.concat(arrAlta);
        params.detalleTransportistaVehiculo = JSON.stringify(arrParams);
      } else {
        this.$message({
          message: "Por favor ingrese los vehículos del transportista",
          type: "error",
          showClose: true,
          duration: 0,
        });
        return false;
      }
      let respuestaApi = await this.$maca.api.post(
        "/transportista/actualizar",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Transportista actualizado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
};
</script>
