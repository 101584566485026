var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Asignación de beneficiario a transportista",
            impedirClose: _vm.impedirClose
          }
        },
        [
          _c(
            "el-card",
            {
              staticClass: "mostrarOcultarCard",
              staticStyle: { margin: "1.5%" },
              attrs: { "body-style": { padding: "2%" }, shadow: "never" }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-position": "top",
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Buscar beneficiario por nombre",
                                prop: "nombre"
                              }
                            },
                            [
                              _c("maca-select-box", {
                                attrs: {
                                  placeholder:
                                    "Ingresar nombre de beneficiario",
                                  "item-label": ["apellido ", "nombre"],
                                  url: _vm.urlCentro,
                                  permitirInput: true,
                                  clearable: true,
                                  necesitaParams: true,
                                  getParams: _vm.getParams
                                },
                                on: { change: _vm.seleccionSelect },
                                model: {
                                  value: _vm.form.beneficiario,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "beneficiario", $$v)
                                  },
                                  expression: "form.beneficiario"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "O filtrar por localidad/paraje" }
                            },
                            [
                              _c("el-cascader", {
                                ref: "cascader",
                                attrs: {
                                  props: _vm.props,
                                  "show-all-levels": false,
                                  placeholder: "Seleccionar ubicación",
                                  clearable: true
                                },
                                on: {
                                  change: _vm.handleChange,
                                  "expand-change": _vm.clickHandleChange
                                }
                              })
                            ],
                            1
                          ),
                          _vm.ubicacionIncompleta
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  "Debe seleccionar la localidad o paraje para poder realizar la búsqueda"
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "48px" },
                          attrs: { span: 2 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success", circle: "" },
                              on: { click: _vm.filtrarBusqueda }
                            },
                            [_c("i", { staticClass: "el-icon-search" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.mostrarTabla
                    ? _c(
                        "maca-datatable",
                        {
                          attrs: {
                            url: _vm.urlTabla,
                            params: _vm.paramsTabla,
                            actualizar: _vm.actualizarTabla,
                            bloquear: _vm.bloquearTabla
                          },
                          on: {
                            "update:actualizar": function($event) {
                              _vm.actualizarTabla = $event
                            },
                            "update:bloquear": function($event) {
                              _vm.bloquearTabla = $event
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "detallePersona.dni",
                              label: "DNI",
                              "min-width": 10
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Apellido y Nombre",
                              "min-width": 17
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            props.row.detallePersona.apellido +
                                              ", " +
                                              props.row.detallePersona.nombre
                                          )
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              573105555
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Direccion", "min-width": 20 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      props.row.detallePersona.detalleParaje !=
                                      null
                                        ? _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                props.row.detallePersona
                                                  .detalleParaje.nombre + ", "
                                              )
                                            }
                                          })
                                        : _vm._e(),
                                      props.row.detallePersona
                                        .detalleLocalidad != null
                                        ? _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                props.row.detallePersona
                                                  .detalleLocalidad.nombre +
                                                  ", "
                                              )
                                            }
                                          })
                                        : _vm._e(),
                                      props.row.detallePersona
                                        .detalleDepartamento != null
                                        ? _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                props.row.detallePersona
                                                  .detalleDepartamento.nombre
                                              )
                                            }
                                          })
                                        : _vm._e(),
                                      props.row.detallePersona
                                        .detalleProvincia != null
                                        ? _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                +" - " +
                                                  props.row.detallePersona
                                                    .detalleProvincia.nombre
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3669278141
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "detallePersona.telefono",
                              label: "Telefono",
                              "min-width": 10
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop:
                                "detalleTipoBeneficiario[0].detalleTipoBeneficiario.nombre",
                              label: "Tipo Beneficio",
                              "min-width": 10
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "detallePersona.fallecido",
                              label: "Fallecido",
                              "min-width": 10
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      props.row.fallecido == 1
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("SI ")]
                                            )
                                          ])
                                        : _c("div", [
                                            _c("span", [_vm._v("NO ")])
                                          ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              748733304
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "tieneAcompaniante",
                              label: "Acompañante",
                              "min-width": 10
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      props.row.tieneAcompaniante == 1
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "orange" }
                                              },
                                              [_vm._v("SI ")]
                                            )
                                          ])
                                        : _c("div", [
                                            _c("span", [_vm._v("NO ")])
                                          ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              131310147
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { width: "70" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "success",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.postApi(props.row)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-check"
                                          })
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3403509749
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticClass: "ocultarMostrarCard",
              staticStyle: { margin: "1.5%" },
              attrs: { "body-style": { padding: "2%" }, shadow: "never" }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-position": "left",
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Buscar beneficiario por nombre",
                                prop: "nombre"
                              }
                            },
                            [
                              _c("maca-select-box", {
                                ref: "markupTree",
                                attrs: {
                                  placeholder:
                                    "Ingresar nombre de beneficiario",
                                  "item-label": ["nombre"],
                                  url: _vm.urlCentro,
                                  permitirInput: true,
                                  clearable: true,
                                  necesitaParams: true,
                                  getParams: _vm.getParams
                                },
                                model: {
                                  value: _vm.form.beneficiario,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "beneficiario", $$v)
                                  },
                                  expression: "form.beneficiario"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "O filtrar por localidad/paraje" }
                            },
                            [
                              _c("el-cascader", {
                                attrs: {
                                  props: _vm.props,
                                  "show-all-levels": false,
                                  placeholder: "Seleccionar ubicación"
                                },
                                on: {
                                  change: _vm.handleChange,
                                  click: _vm.filtrarBusqueda,
                                  "expand-change": _vm.clickHandleChange
                                }
                              })
                            ],
                            1
                          ),
                          _vm.ubicacionIncompleta
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  "Debe seleccionar la localidad o paraje para poder realizar la búsqueda"
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "success", circle: "" } },
                            [_c("i", { staticClass: "el-icon-search" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.mostrarTabla
                    ? _c(
                        "maca-datatable",
                        {
                          attrs: {
                            url: _vm.urlTabla,
                            params: _vm.paramsTabla,
                            actualizar: _vm.actualizarTabla,
                            bloquear: _vm.bloquearTabla
                          },
                          on: {
                            "update:actualizar": function($event) {
                              _vm.actualizarTabla = $event
                            },
                            "update:bloquear": function($event) {
                              _vm.bloquearTabla = $event
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "detallePersona.dni",
                              label: "DNI",
                              "min-width": 10
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Apellido y Nombre",
                              "min-width": 17
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            props.row.detallePersona.apellido +
                                              ", " +
                                              props.row.detallePersona.nombre
                                          )
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              573105555
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Direccion", "min-width": 20 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      props.row.detallePersona
                                        .detalleLocalidad != null
                                        ? _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                props.row.detallePersona
                                                  .detalleLocalidad.nombre +
                                                  ", "
                                              )
                                            }
                                          })
                                        : _vm._e(),
                                      props.row.detallePersona
                                        .detalleDepartamento != null
                                        ? _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                props.row.detallePersona
                                                  .detalleDepartamento.nombre
                                              )
                                            }
                                          })
                                        : _vm._e(),
                                      props.row.detallePersona
                                        .detalleProvincia != null
                                        ? _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                +" - " +
                                                  props.row.detallePersona
                                                    .detalleProvincia.nombre
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3669888725
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "detallePersona.telefono",
                              label: "Telefono",
                              "min-width": 10
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop:
                                "detalleTipoBeneficiario[0].detalleTipoBeneficiario.nombre",
                              label: "Tipo Beneficio",
                              "min-width": 10
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "detallePersona.fallecido",
                              label: "Fallecido",
                              "min-width": 10
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      props.row.fallecido == 1
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("SI ")]
                                            )
                                          ])
                                        : _c("div", [
                                            _c("span", [_vm._v("NO ")])
                                          ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              748733304
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "tieneAcompaniante",
                              label: "Acompañante",
                              "min-width": 10
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      props.row.tieneAcompaniante == 1
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "orange" }
                                              },
                                              [_vm._v("SI ")]
                                            )
                                          ])
                                        : _c("div", [
                                            _c("span", [_vm._v("NO ")])
                                          ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              131310147
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { width: "70" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "success",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.postApi(props.row)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-check"
                                          })
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3403509749
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }