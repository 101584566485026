var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: { titulo: "Beneficiarios", impedirClose: _vm.impedirClose }
        },
        [
          _c(
            "el-card",
            {
              staticStyle: { margin: "2%" },
              attrs: { "body-style": { padding: "1.5%" } }
            },
            [
              _c(
                "div",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "0px" } },
                    [
                      _c("el-input", {
                        staticClass: "buscador tamañoInputBucar",
                        attrs: {
                          "prefix-icon": "el-icon-search",
                          placeholder: "Buscar"
                        },
                        model: {
                          value: _vm.filtroNombre,
                          callback: function($$v) {
                            _vm.filtroNombre = $$v
                          },
                          expression: "filtroNombre"
                        }
                      }),
                      _c("el-button", {
                        staticClass: "botonmasIcono",
                        staticStyle: { "margin-left": "0.2rem" },
                        attrs: {
                          icon: "el-icon-refresh",
                          type: "primary",
                          circle: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.recargarTabla()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-button", {
                    staticClass: "botonmasIcono ocultarMostrarItem",
                    staticStyle: { "margin-left": "0.2rem" },
                    attrs: {
                      icon: "el-icon-plus",
                      type: "primary",
                      circle: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.$refs.modalNuevo.abrir()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "maca-datatable",
                    {
                      attrs: {
                        url: _vm.urlTabla,
                        params: _vm.paramsTabla,
                        actualizar: _vm.actualizarTabla,
                        bloquear: _vm.bloquearTabla
                      },
                      on: {
                        "update:actualizar": function($event) {
                          _vm.actualizarTabla = $event
                        },
                        "update:bloquear": function($event) {
                          _vm.bloquearTabla = $event
                        }
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "Centro" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                props.row.centroID != null
                                  ? _c("div", [
                                      _c("i", {
                                        staticClass: "el-icon-office-building"
                                      })
                                    ])
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Apellido" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(props.row.apellido)
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Nombre" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(props.row.nombre)
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "razonSocial", label: "Razon Social" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cuitCuil", label: "CUIT/CUIL" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "telefono", label: "Teléfono" }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Vive" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                props.row.fallecido == 0
                                  ? _c("div", [_c("span", [_vm._v("SI ")])])
                                  : _c("div", [_c("span", [_vm._v("NO ")])])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop:
                            "detalleTransportistaTipoBeneficiario[0].detalleTipoBeneficiario.nombre",
                          label: "Tipo"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { width: "70" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      circle: "",
                                      disabled: !_vm.$store.getters.tienePermiso(
                                        "B_USE"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.eliminar(props.row.id)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-delete" })]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }